.nav_bg {
  background: rgb(93, 166, 80);
  background: linear-gradient(
    194deg,
    rgba(93, 166, 80, 1) 0%,
    rgba(74, 173, 55, 1) 12%,
    rgb(74, 173, 55) 74%,
    rgba(129, 214, 113, 1) 100%
  );
}

.eco_city_nav_bg {
  background: rgb(26, 235, 198);
  background: linear-gradient(
    59deg,
    rgba(26, 235, 198, 1) 0%,
    rgba(0, 174, 239, 1) 100%
  );
}

/* Neomorphic backgrounds */
.neo_nav_out {
  background: linear-gradient(145deg, #3f932f, #55c73f);
  box-shadow: 8.11px 8.11px 19px #29601f, -8.11px -8.11px 19px #6bfa4f;
}

.bg_neo {
  background: #fff;
  box-shadow: 5.11px 5.11px 17px #f1efef, -5.11px -5.11px 17px #b5c2e7;
  background: linear-gradient(145deg, #606a88, #f1f1f4);
}

.rough_bg {
  background-image: url('https://www.transparenttextures.com/patterns/black-paper.png');
}

.rough_bg2 {
  background-image: url('https://www.transparenttextures.com/patterns/brushed-alum-dark.png');
}

.leaf_bg {
  background-color: #4aad37;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%2399df8a' fill-opacity='0.4' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
}
