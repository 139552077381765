
.link_shadow {
  box-shadow: 0px -17px 20px -20px rgba(0, 0, 0, 0.45) inset;
}

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.triangular_shape {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

/* Pet icon on nav */
.pet_icon {
  fill: #000000; /* Default fill color */
  transition: fill 0.3s ease; /* Optional: for a smooth color transition */
}

.pet_icon:hover {
  fill: #ffffff; /* Fill color on hover */
}


